import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { environment } from 'src/app/environment/environment';
import { SharedService } from '../../services/shared.service';
import { hme_consent_changePayload } from '../../interfaces/hme_consent_interfaces';
import { switchboardCall } from 'src/app/utils/common_functions';
import { openModal, closeModal } from 'src/app/utils/common_modal_functions';
import { countries, languages } from 'src/app/utils/common_lists';
import { errorTimeout } from 'src/app/utils/common_vars';
import { enrollOnboardingPayload } from 'src/app/interfaces/trigger_survey_interfaces';

declare var $: any;

@Component({
  selector: 'app-hme-consent',
  templateUrl: './hme-consent.component.html',
  styleUrl: './hme-consent.component.scss',
})
export class HmeConsentComponent {
  hme_consent_changePayload: hme_consent_changePayload = {
    patientId: '',
    sourceId: '',
    shareWithProvider: false,
    flowgenType: 'as11',
    countryCode: '',
    locale: '',
  };
  hme_consent_changeForm: any;
  hme_consent_changeErr: boolean = false;
  selectedCountry: any;
  selectedLanguage: any;
  missingCountry: boolean = false;
  missingLocale: boolean = false;
  submitStatus: boolean = false;
  generatedPatientID: string = '';
  generatedSourceID: string = '';
  hmeError: boolean = false;
  selectedSurveyId: any;
  missingSurvey: any;
  generatedPatientIDTrigger: any;
  dayOffset: any;
  deviceSerial: any;

  countries = countries;
  languages = languages;

  constructor(
    private elementRef: ElementRef,
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef,
    private platformLocation: PlatformLocation,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sharedService.buttonClick$.subscribe(() => {
      this.handleHmeOpen();
    });
  }

  ngAfterViewInit(): void {
    $('#device-type').select2({
      width: '100%',
    });

    $('#country-dropdown').select2({
      width: '100%',
      placeholder: 'Select an option',
    });

    $('#language-dropdown').select2({
      width: '100%',
      placeholder: 'Select an option',
    });
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    // Destroy select2 instances for specific IDs
    if ($('#device-type').data('select2')) {
      $('#device-type').select2('destroy');
    }

    if ($('#country-dropdown').data('select2')) {
      $('#country-dropdown').select2('destroy');
    }

    if ($('#language-dropdown').data('select2')) {
      $('#language-dropdown').select2('destroy');
    }
    this.cdr.detectChanges();
  }

  handleHmeOpen() {
    openModal(
      'hmeConsentChangeModal',
      ['patient-id-hme_consent_change', 'source-id-hme_consent_change'],
      this.elementRef
    );
  }

  async hmeConsentChange(): Promise<void> {
    this.hme_consent_changeErr = false;
    const patientIdIn = this.elementRef.nativeElement.querySelector(
      '#patient-id-hme_consent_change'
    ) as HTMLInputElement;
    const sourceIdIn = this.elementRef.nativeElement.querySelector(
      '#source-id-hme_consent_change'
    ) as HTMLInputElement;
    const shareWithProviderIn = this.elementRef.nativeElement.querySelector(
      '#share-with-provider'
    ) as HTMLInputElement;

    //Check if any fields are missing and trigger error
    if (!$('#country-dropdown')?.val()) {
      this.hme_consent_changeErr = true;
      this.missingCountry = true;
      this.cdr.detectChanges();
      console.log(this.missingCountry);
      setTimeout(() => {
        this.missingCountry = false;
        this.cdr.detectChanges();
      }, errorTimeout);
    }

    if (!$('#language-dropdown')?.val()) {
      this.hme_consent_changeErr = true;
      this.missingLocale = true;
      this.cdr.detectChanges();
      console.log(this.missingLocale);
      setTimeout(() => {
        this.missingLocale = false;
        this.cdr.detectChanges();
      }, errorTimeout);
    }

    if (this.hme_consent_changeErr) {
      console.log('Missing fields');
      this.hme_consent_changeErr = false;
      return;
    }

    const hme_consent_changePayload: hme_consent_changePayload = {
      patientId: patientIdIn.value,
      sourceId: sourceIdIn.value,
      shareWithProvider: shareWithProviderIn.checked,
      flowgenType: $('#device-type').val(),
      countryCode: $('#country-dropdown')
        .val()
        .match(/:\s*(\S+)/)?.[1],
      locale: $('#language-dropdown')
        .val()
        .match(/:\s*(\S+)/)?.[1],
    };

    const onboardingPayload: enrollOnboardingPayload = {
      patientId: patientIdIn.value,
      type: 'onboarding',
      isoCountryCode: $('#country-dropdown')
        .val()
        .match(/:\s*(\S+)/)?.[1],
      locale: $('#language-dropdown')
        .val()
        .match(/:\s*(\S+)/)?.[1],
      flowgenType: $('#device-type').val(),
    };

    console.log(
      'Executing hme_consent switchboard call with payload: ',
      hme_consent_changePayload
    );
    await this.executeHME(hme_consent_changePayload, onboardingPayload);
  }

  /**
   * Call switchboard to enroll new patient using HME consent v2
   * New patient will also automatically be enrolled into subjective-feedback-v1
   */
  async executeHME(
    hmePayload: hme_consent_changePayload,
    onboardingPayload: enrollOnboardingPayload
  ): Promise<void> {
    try {
      const response = await switchboardCall(
        { data: { hmePayload, onboardingPayload }, action: 'enroll' },
        environment.switchboardUri,
        this.platformLocation,
        this.httpClient
      );
      if (Object.entries(response).length === 0) {
        console.log('HME Consent Change Failed');
        this.triggerHmeError();
        return;
      }
      console.log('HME Consent Change successful');
      closeModal(
        'hmeConsentChangeModal',
        ['patient-id-hme_consent_change'],
        this.elementRef
      );
      // Display success status to user
      this.submitStatus = true;
      this.cdr.detectChanges();
      setTimeout(() => {
        this.submitStatus = false;
        this.cdr.detectChanges();
      }, errorTimeout);
    } catch (error) {
      console.error(error);
      this.triggerHmeError();
    }
  }

  triggerHmeError(): void {
    this.hmeError = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.hmeError = false;
      this.cdr.detectChanges();
    }, errorTimeout);
  }

  closeModal(id: string, drpdownIds: string[]) {
    closeModal(id, drpdownIds, this.elementRef);
  }
}
