import { triggerSurveyChangePayload } from '../../interfaces/trigger_survey_interfaces';
import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { environment } from 'src/app/environment/environment';
import { SharedService } from '../../services/shared.service';
import { sbxUriBuilder } from 'src/app/utils/common_functions';
import {
  openModal,
  closeModal,
  checkEmptyInputs,
} from 'src/app/utils/common_modal_functions';
import { surveys } from 'src/app/utils/common_lists';

declare var $: any;
@Component({
  selector: 'app-trigger-survey',
  templateUrl: './trigger-survey.component.html',
  styleUrl: './trigger-survey.component.scss',
})
export class TriggerSurveyComponent {
  selectedSurveyId: string = '';
  generatedPatientIDTrigger: string = '';
  missingSurvey: boolean = false;
  dayOffset: string = '';
  lastDayOffsetTrg: string = '';
  deviceSerial: string = '';
  triggerError: boolean = false;
  triggerFieldError: boolean = false;
  submitStatus: boolean = false;

  errorTimeout: number = 2225;

  surveys = surveys;

  constructor(
    private elementRef: ElementRef,
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef,
    private platformLocation: PlatformLocation,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sharedService.triggerOpen$.subscribe(() => {
      this.handleTriggerOpen();
    });
  }

  ngAfterViewInit(): void {
    $('#survey-dropdown').select2({
      width: '100%',
      placeholder: 'Select an option',
    });
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    // Destroy select2 instances for specific IDs
    $('#survey-dropdown').select2('destroy');
    this.cdr.detectChanges();
  }

  handleTriggerOpen() {
    openModal(
      'triggerSurveyModal',
      ['patient-id-trigger_survey'],
      this.elementRef
    );
  }

  validateSelection() {
    this.missingSurvey = !this.selectedSurveyId;
  }

  triggerSurvey(): void {
    this.triggerError = false;
    this.triggerFieldError = false;
    const patientIdIn = this.elementRef.nativeElement.querySelector(
      '#patient-id-trigger_survey'
    ) as HTMLInputElement;
    const deviceSerialIn = this.elementRef.nativeElement.querySelector(
      '#device-serial-trigger_survey'
    ) as HTMLInputElement;
    const dayOffset = this.elementRef.nativeElement.querySelector(
      '#day-offset-trigger_survey'
    ) as HTMLInputElement;

    if (!$('#survey-dropdown')?.val()) {
      this.triggerFieldError = true;
      this.missingSurvey = true;
      this.cdr.detectChanges();
      console.log(this.missingSurvey);
      setTimeout(() => {
        this.missingSurvey = false;
        this.cdr.detectChanges();
      }, this.errorTimeout);
    }

    checkEmptyInputs(
      [
        'day-offset-trigger_survey',
        'last-day-offset-trigger_survey',
        'device-serial-trigger_survey',
      ],
      this.triggerFieldError
    );

    if (this.triggerFieldError) {
      console.log('Missing fields');
      this.triggerFieldError = false;
      return;
    }

    let lastDayOffset;
    if (Number(dayOffset.value) === 4 || Number(dayOffset.value) === 7) {
      lastDayOffset = 3;
    } else if (Number(dayOffset.value) % 7 === 1) {
      lastDayOffset = Number(dayOffset.value) - 1;
    } else {
      lastDayOffset = Math.max(0, Number(dayOffset.value) - 7);
    }

    let surveyId = $('#survey-dropdown')
      .val()
      .match(/:\s*(\S+)/)?.[1];

    const triggerSurveyChangePayload: triggerSurveyChangePayload = {
      patient_id: patientIdIn.value,
      day_offset: Number(dayOffset.value),
      last_day_offset: lastDayOffset,
      device_serial: deviceSerialIn.value,
      survey_id: surveyId,
      source_id: `DEVICE_SERIAL#${deviceSerialIn.value}`,
      scheduler_type: 'SURVEY_TRIGGER',
    };
    console.log(
      'Executing trigger survey switchboard call with payload: ',
      triggerSurveyChangePayload
    );
    this.executeTrigger(triggerSurveyChangePayload);
  }

  async executeTrigger(payload: triggerSurveyChangePayload): Promise<void> {
    try {
      let uri = environment.switchboardUri;

      if (uri.indexOf('honeybadgers-dev') >= 0) {
        uri = sbxUriBuilder(uri, this.platformLocation);
      }

      const response = await this.httpClient
        .post<any>(uri, {
          data: payload,
          action: 'trigger',
          scheduler_type: 'SURVEY_TRIGGER',
        })
        .toPromise();
      console.log('Response: ', response);

      console.log('Trigger Survey Succesful');
      closeModal('triggerSurveyModal', ['survey-dropdown'], this.elementRef);
      this.submitStatus = true;
      this.cdr.detectChanges();
      setTimeout(() => {
        this.submitStatus = false;
        this.cdr.detectChanges();
      }, this.errorTimeout);
    } catch (error) {
      this.triggerSurveyError();
      console.error(error);
    }
  }

  triggerSurveyError(): void {
    this.triggerError = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.triggerError = false;
      this.cdr.detectChanges();
    }, this.errorTimeout);
  }

  closeModal(id: string, drpdownIds: string[]) {
    closeModal(id, drpdownIds, this.elementRef);
  }
}
