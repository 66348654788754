<!-- Alert messages -->
<div *ngIf="submitStatus" class="modal-success">
  Success!
</div>

<div id="hmeConsentChangeModal" class="modal">
  <div class="modal-content">
    <div>
      <span class="close" (click)="closeModal('hmeConsentChangeModal', ['country-dropdown', 'language-dropdown'])">&times;</span>
      <h1>HME Consent Change</h1>
    </div>
    <div class="survey-fields">
      <div class="provider-checkbox">
        <p class="title-option"><strong>Share with Provider</strong></p>
        <input type="checkbox" id="share-with-provider" class="">
      </div>
      <div class="patient-id">
        <p><strong>Patient ID</strong></p>
        <input type="text" id="patient-id-hme_consent_change" class="site-nav__textbox" [(ngModel)]="generatedPatientID">
      </div>

      <div class="source-id">
        <p><strong>Source ID</strong></p>
        <input type="text" id="source-id-hme_consent_change" class="site-nav__textbox" [(ngModel)]="generatedSourceID">
      </div>

      <div class="select">
        <p><strong>Device Type</strong></p>
        <select id="device-type" class="site-nav__dropdown">
          <option value="AS11" selected>AirSense 11</option>
          <option value="AC11">AirCurve 11</option>
        </select>
      </div>

      <div class="select">
        <p><strong>Select Country</strong></p>
        <select id="country-dropdown" class="site-nav__dropdown" [(ngModel)]="selectedCountry">
          <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.name }} ({{ country.code }})</option>
        </select>
        <div id="countryError" class="modal-error" [class.show]="missingCountry">
          Please select a country
        </div>
      </div>
      
      <div class="select">
        <p><strong>Select Language</strong></p>
        <select id="language-dropdown" class="site-nav__dropdown" [(ngModel)]="selectedLanguage">
          <option *ngFor="let language of languages" [ngValue]="language.locale">{{ language.language }} ({{ language.locale }})</option>
        </select>
        <div id="languageError" class="modal-error" [class.show]="missingLocale">
          Please select a locale
        </div>
      </div>

      <button class="submit-btn" (click)="hmeConsentChange()"><strong>Submit</strong></button>
      <div *ngIf="hmeError" class="modal-submit-error">
        Error, Check Logs
      </div>
    </div>
  </div>
</div>